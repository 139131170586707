<template>
  <DataTable :value="list"
             :auto-layout="true">
    <template #header>
      <div style="display: flex">
        <Button class="p-mr-2" style="height: 33px" @click="addOrUpdate(null)">添加用户</Button>
        <InputText type="text" v-model="name" placeholder="查找用户名" @change="getData"></InputText>
        <Dropdown class="p-ml-2" v-model="industry" :options="industryList" optionLabel="name"
                  placeholder="专业领域" option-value="name" :show-clear="true"/>
        <Dropdown class="p-ml-2" v-model="state" :options="stateList" optionLabel="name"
                  placeholder="类型" option-value="code" :show-clear="true"/>
        <InputNumber class="p-ml-2" :useGrouping="false" v-model="phone" placeholder="查找电话"></InputNumber>
        <Button class="p-ml-2" @click="getData">查询</Button>
      </div>
    </template>
    <Column field="userName" header="名称"></Column>
    <Column field="pic" header="头像">
      <template #body="a">
       <img v-if="a.data.pic" style="width: 80px" :src="picUrl+a.data.pic">
      </template>
    </Column>
    <Column field="postTime" header="添加时间">
      <template #body="a">
        {{ new Date(a.data.postTime).toLocaleString() }}
      </template>
    </Column>
    <Column field="phone" header="电话"></Column>
    <Column field="sex" header="性别"></Column>
    <Column field="industry" header="专业领域"></Column>
    <Column field="degree" header="学历"></Column>
    <Column field="expert" header="类型">
      <template #body="a">
        {{ a.data.expert == 0 ? '普通用户' : (a.data.expert==1?'专家':(a.data.expert==2?'企业':'')) }}
      </template>
    </Column>
    <Column header="操作">
      <template #body="a">
        <Button class="p-button-sm p-mr-2" @click="addOrUpdate(a.data)">编辑</Button>
        <Button class="p-button-sm p-button-danger p-mr-2" @click="deleteDialog(a.data)">删除</Button>
      </template>
    </Column>
  </DataTable>
  <Paginator @page="changePage" :rows="page.size" :totalRecords="page.total" :rowsPerPageOptions="[5,10,25]"
             currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录"
             template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"></Paginator>

  <Toast/>
  <Dialog v-model:visible="delShow" :style="{width: '350px'}" header="确认" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
      <span v-if="publicUser">你确认要删除 <b>{{ publicUser.userName }}</b>?</span>
    </div>
    <template #footer>
      <Button label="否" icon="pi pi-times" class="p-button-text" @click="delShow = false"/>
      <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteUser(publicUser.id)"/>
    </template>
  </Dialog>
  <AddOrUpdatePublicUser v-on:close="closeDialog" v-if="show" ref="addOrUpdatePublicUser"></AddOrUpdatePublicUser>
</template>

<script>
import AddOrUpdatePublicUser from "@/views/modules/publicUser/addOrUpdatePublicUser";
export default {
  name: "PublicList",
  components: {AddOrUpdatePublicUser},
  data(){
    return{
      name:null,
      state:null,
      phone:null,
      industry:null,
      industryList:[],
      stateList:[
        {name:'普通用户',code:0},
        {name:'专家',code:1},
        {name:'企业用户',code:2}
      ],
      show:false,
      delShow:false,
      publicUser:{},
      picUrl:this.$global_msg.imgurl,
      list:[],
      page: {
        size: 10,
        current: 1,
        total: 0
      }
    }
  },
  mounted() {
    this.getData()
    this.getIndustry()
  },
  methods:{
    getData(){
      this.$http.get('/publicUser/getList',{
        params:{
          current: this.page.current,
          size: this.page.size,
          name:this.name,
          state:this.state,
          phone:this.phone,
          industry:this.industry
        }
      }).then((res)=>{
        this.page.total = res.data.total
        this.list = res.data.records
      })
    },
    changePage(event) {
      this.page.size = event.rows
      this.page.current = event.page + 1
      this.getData()
    },
    getIndustry() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'zhuanyelingyu'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }

        this.industryList = resList;
      })
    },
    addOrUpdate(data){
      this.show=true
      this.$nextTick(()=>{
        this.$refs.addOrUpdatePublicUser.init(data)
      })
    },
    deleteDialog(data){
      this.publicUser=data
      this.delShow=true
    },
    deleteUser(id){
      this.$http.post('/publicUser/delPublicUser/'+id).then(()=>{
        this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
        this.delShow=false
        this.getData()
      })
    },
    closeDialog(){
      this.show=false;
      this.getData()
    }
  }
}
</script>

<style scoped>

</style>