<template>
  <Dialog v-model:visible="show" style="width: 30%" :header="form.id==null?'添加用户':'编辑用户'">
    <div style="margin: 0 10%">
      <div class="p-field">
        <label style="width: 100px" for="name">用户名：</label>
        <InputText style="width: calc(100% - 100px)" v-model="form.userName" id="name" type="text"/>
      </div>
      <div class="p-field">
        <label style="width: 100px">专业领域：</label>
        <Dropdown style="width: calc(100% - 100px)" v-model="form.industry" :options="industryList" optionLabel="name"
                  placeholder="专业领域" option-value="name" :show-clear="true"/>
      </div>
      <div class="p-field">
        <label style="width: 100px" for="photo">电话：</label>
        <InputNumber style="width: calc(100% - 100px)" v-model="form.phone" id="photo" :useGrouping="false"
                     :maxFractionDigits="11"/>
      </div>
      <div class="p-field">
        <label style="width: 100px">学历：</label>
        <Dropdown style="width: calc(100% - 100px)" v-model="form.degree" :options="degreeList" option-label="name"
                  placeholder="学历" option-value="name" :show-clear="true"/>
      </div>
      <div class="p-field">
        <label style="width: 100px">类型：</label>
        <Dropdown style="width: calc(100% - 100px)" v-model="form.expert" :options="stateList" option-label="name"
                  placeholder="类型" option-value="code" :show-clear="true"/>
      </div>
      <div class="p-field">
        <Button @click="submit()" style="margin-left: 100px">提交</Button>
      </div>
    </div>
  </Dialog>
  <Toast/>
</template>

<script>
export default {
  name: "addOrUpdatePublicUser",
  data() {
    return {
      industryList: [],
      degreeList: [],
      stateList: [
        {name: '普通用户', code: 0},
        {name: '专家', code: 1},
        {name: '企业用户', code: 2}
      ],
      show: false,
      form: {
        id: null,
        industry: null,
        userName: null,
        sex: null,
        phone: null,
        degree: null,
        expert: 0
      }
    }
  },
  methods: {
    init(data) {
      this.show = true
      this.getIndustry()
      this.getDegreeList()
      if (data != null) {
        this.form = data
        console.log(this.form.expert)
      }
    },
    getIndustry() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'zhuanyelingyu'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }
        this.industryList = resList;
      })
    },
    getDegreeList() {
      this.$http.get('/dictionary/selectValue', {params: {key: 'xueliyaoqiu'}}).then((res) => {
        let list = res.data.myValue.split(",");
        let resList = []
        for (let i in list) {
          let item = {}
          item.name = list[i]
          resList.push(item)
        }
        this.degreeList = resList;
      })
    },
    submit() {
      this.$http.post('/publicUser/addOrUpdate', this.form).then((res) => {
        if(res.data){
          this.$toast.add({severity: 'success', summary: '成功', detail: this.form.id ? '修改成功' : '添加成功', life: 3000});
        }else {
          this.$toast.add({severity: 'info', summary: '失败', detail: '用户已存在', life: 3000});
        }
        this.show = false
        this.$emit('close')
      })
    }
  }
}
</script>

<style scoped>

</style>